
import { Component, InjectReactive, Vue } from "vue-property-decorator";
import { USER_PROFILE_SYMBOL } from "@/constants";
import Amount from "@/components/balance/Amount.vue";
import CurrencyIcon from "@/components/balance/CurrencyIcon.vue";

@Component({
  meta: {
    title: "Бонусная система"
  },
  components: {
    Amount,
    CurrencyIcon
  }
})
export default class Bonus extends Vue {
  @InjectReactive(USER_PROFILE_SYMBOL) user;
  arrowImage = require(`../assets/img/bonus/bonus-arrow.svg`);
  levelImages = [
    require(`../assets/img/bonus/level-1.svg`),
    require(`../assets/img/bonus/level-2.svg`),
    require(`../assets/img/bonus/level-3.svg`),
    require(`../assets/img/bonus/level-4.svg`),
    require(`../assets/img/bonus/level-5.svg`),
    require(`../assets/img/bonus/level-6.svg`),
    require(`../assets/img/bonus/level-7.svg`)
  ];
  levelNames = [
    "Стартер",
    "Новичок",
    "Любитель",
    "Профи",
    "Красавчик",
    "Мажор",
    "Бог"
  ];

  getLevelImage(level) {
    if (this.levelImages[level - 1]) {
      return this.levelImages[level - 1];
    } else {
      return this.levelImages[this.levelImages.length - 1];
    }
  }

  getLevelName(level) {
    if (this.levelNames[level - 1]) {
      return this.levelNames[level - 1];
    } else {
      return this.levelNames[this.levelNames.length - 1];
    }
  }
}
